import React, { useContext, useState } from 'react'
import { navigate } from 'gatsby'
import AppContext from '../../../../../src/common/context/context'
import Step, {
  StepDetails,
} from '../../../../../src/common/components/FunnelSelector/SelectorSteps'
import { StepDetails as StepDetailsMoto } from '../../../../../src/common/components/FunnelSelectorMoto/SelectorMotoSteps'
import { Step as StepMoto } from '../../../../../src/common/context/reducers/selectorMotoReducer'
import SelectorModal from '../../../../../src/common/components/FunnelSelector/Entrada/SelectorModal'
import CestaLastRow from '../../../../../src/common/components/cesta/cestaItem/cestaLastRow/CestaLastRow'
import { checkIfCestaHasProductsMoto } from '../../../../../src/common/context/actions/cestaActions'
import SelectorModalMoto from '../../../../../src/common/components/FunnelSelectorMoto/Results/SelectorModalMoto'
import { t } from '../../../i18n'
import { reset } from '../../../../../src/common/context/actions/selectorActions'
import route from '../../../../../src/common/utils/route'
import { isCestaExternal } from '../../../../../src/common/context/selectors/cestaSelectors'

type Props = {
  onComprarClick: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
}
export const BuscarNeumaticosCesta = ({ onComprarClick }: Props) => {
  const { cestaState, dispatch } = useContext(AppContext)
  const [showModal, setShowModal] = useState(false)
  const onSubmitCoche = () => {
    navigate(StepDetails[Step.SHOW_RESULTADOS].routes.desktop)
  }
  const onSubmitMoto = () => {
    navigate(StepDetailsMoto[StepMoto.SHOW_RESULTADOS].routes.desktop)
  }
  const openSelectorModal = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault()
    if (isCestaExternal(cestaState)) {
      dispatch(
        reset({
          keepValues: false,
          marca: null,
          origen: route('cesta.solicitud'),
          temporada: {
            selectable: process.env.GATSBY_WEB === 'rodi',
            value: 'verano',
          },
          tipoVehiculo: null,
        })
      )
    }
    setShowModal(true)
    return null
  }
  return (
    <>
      {checkIfCestaHasProductsMoto(cestaState) ? (
        <SelectorModalMoto
          closeModal={() => {
            setShowModal(false)
          }}
          showModal={showModal}
          title={t('selector.modifica_busqueda')}
          handleSubmit={onSubmitMoto}
          showMarcas={false}
        />
      ) : (
        <SelectorModal
          title={t('tu_solicitud.elige_neumatico')}
          closeModal={() => {
            setShowModal(false)
          }}
          showModal={showModal}
          onSubmit={onSubmitCoche}
        />
      )}
      <CestaLastRow
        onContinuarClick={onComprarClick}
        openSelectorModal={openSelectorModal}
      />
    </>
  )
}
