import { navigate } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import {
  checkIfCestaHasProductsMoto,
  removeProduct,
  resetCestaError,
  setStep,
  updateQuantity,
} from '../../../context/actions/cestaActions'
import { reset } from '../../../context/actions/selectorActions'
import AppContext from '../../../context/context'
import {
  hasServices,
  isPromocionForKits,
  isPromocionForProductos,
  selectGlobalDiscountAmount,
  selectImporteTotalProductos,
  selectNumeroNeumaticos,
} from '../../../context/selectors/cestaSelectors'
import { t } from '../../../i18n'
import Size from '../../../utils/media-queries-setup'
import route from '../../../utils/route'
import { alertProp } from '../../../utils/swal'
import FunnelSideBar from '../../funnelSideBar/FunnelSideBar'
import CestaItem from '../cestaItem/CestaItem'
import CestaLastRow from '../cestaItem/cestaLastRow/CestaLastRow'
import CestaRowWithCupon from '../cestaItem/cestaRowWithCupon/CestaRowWithCupon'
import { CestaPageProps } from '../registro/CestaRegistro'
import { DataSteps, Steps } from '../Steps'
import styles from './index.module.scss'
import { isAndorraPostalCode } from '../../../utils/getTarifa'
import { BuscarNeumaticosCesta } from '../../../../specific/components/cesta/editBuscarNeumaticos/buscarNeumaticosCesta'
import { CategoriaServicio } from '../../../types/CategoriaServicio'

const TuSolicitud: React.FC<CestaPageProps> = () => {
  const { cestaState, userInfo, dispatch } = useContext(AppContext)
  const [isAndorra, setIsAndorra] = useState(false)

  if (typeof window !== 'undefined' && !cestaState.products.length) {
    navigate(route('index'))
  }

  useEffect(() => {
    if (!isAndorra) {
      if (
        !!cestaState.selectedTaller &&
        isAndorraPostalCode(cestaState.selectedTaller.cpostal)
      ) {
        setIsAndorra(true)
      } else if (
        !cestaState.selectedTaller &&
        isAndorraPostalCode(userInfo.postCode)
      ) {
        setIsAndorra(true)
      }
    }
  }, [])

  if (cestaState.error) {
    Swal.fire(
      alertProp({
        type: 'warning',
        title: 'Oops...',
        text: t(`cesta.errores.${cestaState.error}`),
      })
    )
    dispatch(resetCestaError())
  }
  const isMobile = Size.useMedia({ mobile: true, tablet: true })

  const handleQuantityChange = (value, id) => {
    let typeOfChange
    const product = cestaState.products.find((item) => item.id_navision === id)
    if (product) {
      const currentQuantity = cestaState.products.find(
        (item) => item.id_navision === id
      ).cantidad
      typeOfChange = currentQuantity > value ? 'remove' : 'add'
    }
    const valueDifference = Math.abs(Number(product.cantidad) - Number(value))
    dispatch(updateQuantity(id, value, typeOfChange, valueDifference))
  }

  const onDeleteItem = (id) => {
    const product = cestaState.products.find((item) => item.id_navision === id)
    dispatch(removeProduct(id, product))
  }
  const openSelectorModal = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault()
    if (isMobile) {
      dispatch(
        reset({
          keepValues: false,
          marca: null,
          origen: route('cesta.solicitud'),
          temporada: {
            selectable: process.env.GATSBY_WEB === 'rodi',
            value: 'verano',
          },
          tipoVehiculo: null,
        })
      )
      if (checkIfCestaHasProductsMoto(cestaState)) {
        return navigate(route('selector-moto.ancho'))
      } else {
        return navigate(route('selector.ancho'))
      }
    }
  }

  const onComprarClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    const quantity = selectNumeroNeumaticos(cestaState)
    if (quantity > 6) {
      alert('No se pueden elegir mas de 6 neumáticos')
      return
    }

    // move to next step
    if (!hasServices(cestaState)) {
      dispatch(setStep(Steps.TALLERES))
      navigate(DataSteps[Steps.TALLERES].route)
    } else {
      dispatch(setStep(Steps.OPCIONES))
      navigate(DataSteps[Steps.OPCIONES].route)
    }
  }

  const totalProductos = selectImporteTotalProductos(cestaState)
  const globalDiscountAmount = selectGlobalDiscountAmount(cestaState)

  return (
    <div className={styles.tu_solicitud_container}>
      <div className={styles.top_area}>{t('tu_solicitud.title')}</div>
      <div className={styles.tu_solicitud}>
        <div className={styles.iva_incl_mobile}>
          {t(
            isAndorra
              ? 'tu_solicitud.igi_incluido'
              : 'tu_solicitud.iva_incluido'
          )}
        </div>
        <div className={styles.solicitud_wrapper}>
          <div className={styles.service_row}>
            {t('cesta.solicitud.servicios_interesantes')}:
          </div>
          <div>
            {cestaState.products.map((item, index) => (
              <React.Fragment key={index}>
                <CestaItem
                  titleClass={styles.product_title}
                  item={item}
                  onDeleteItem={onDeleteItem}
                  key={index}
                  handleQuantityChange={handleQuantityChange}
                  hasDelete={true}
                />
                {item.importe !== item.importeSinPromo && (
                  <CestaItem
                    titleClass={styles.service_title}
                    highlighted
                    key={cestaState.discount.tipo}
                    item={{
                      descripcion_ca: t('descuentoIVA'),
                      descripcion_es: t('descuentoIVA'),
                      nombre_producto_ca: t('descuento'),
                      nombre_producto_es: t('descuento'),
                      importe: item.importe - item.importeSinPromo,
                    }}
                  />
                )}
                {item.servicios
                  .filter(
                    (serv) =>
                      serv.categoriaServicio !== CategoriaServicio.Montaje
                  )
                  .map((_item, index) => (
                    <CestaItem
                      titleClass={styles.service_title}
                      key={index}
                      item={_item}
                    />
                  ))}
                {item.promocion.id_site_promocion_cesta &&
                item.promocion.importePromocion > 0 &&
                isPromocionForProductos(item.promocion) ? (
                  <CestaItem
                    titleClass={styles.service_title}
                    highlighted
                    key={item.promocion.id_site_promocion_cesta}
                    item={{
                      descripcion_ca:
                        item.promocion.descripcion_ca || 'Concepte:',
                      descripcion_es:
                        item.promocion.descripcion_es || 'Concepto:',
                      descripcion_pt:
                        item.promocion.descripcion_pt || 'Conceito:',
                      nombre_producto_ca: t('descuento'),
                      nombre_producto_es: t('descuento'),
                      nombre_producto_pt: t('descuento'),
                      importe: -item.promocion.importePromocion,
                    }}
                  />
                ) : null}
                {item.promocion.id_site_promocion_cesta &&
                item.promocion.importePromocion > 0 &&
                isPromocionForKits(item.promocion) &&
                item.servicios.find(
                  (servicio) =>
                    servicio.categoriaServicio === item.promocion.tipo
                ) ? (
                  <CestaItem
                    titleClass={styles.service_title}
                    highlighted
                    key={item.promocion.id_site_promocion_cesta}
                    item={{
                      descripcion_ca:
                        item.promocion.descripcion_ca || 'Concepte:',
                      descripcion_es:
                        item.promocion.descripcion_es || 'Concepto:',
                      descripcion_pt:
                        item.promocion.descripcion_pt || 'Conceito:',
                      nombre_producto_ca: t('descuento'),
                      nombre_producto_pt: t('descuento'),
                      nombre_producto_es: t('descuento'),
                      importe: -(
                        (item.servicios.find(
                          (servicio) =>
                            servicio.categoriaServicio === item.promocion.tipo
                        ).importe *
                          item.promocion.importePromocion) /
                        100
                      ),
                    }}
                  />
                ) : null}
              </React.Fragment>
            ))}
            {globalDiscountAmount > 0 ? (
              <CestaItem
                titleClass={styles.service_title}
                highlighted
                key={cestaState.discount.tipo}
                item={{
                  descripcion_ca:
                    cestaState.discount.descripcion_ca || 'Concepte:',
                  descripcion_es:
                    cestaState.discount.descripcion_es || 'Concepto:',
                  descripcion_pt:
                    cestaState.discount.descripcion_pt || 'Conceito:',
                  nombre_producto_ca: t('descuento'),
                  nombre_producto_es: t('descuento'),
                  nombre_producto_pt: t('descuento'),
                  importe: -globalDiscountAmount,
                }}
              />
            ) : null}
          </div>

          {openSelectorModal && (
            <a
              onClick={openSelectorModal}
              className={styles.more_neumaticos_mobile}>
              {t('cesta.general.añadir_neumaticos')}
            </a>
          )}
          <CestaRowWithCupon
            price={totalProductos - globalDiscountAmount}
            displayCouponBox={cestaState.displayCouponBox}
          />
        </div>
        <Size.Matcher
          desktop={<BuscarNeumaticosCesta onComprarClick={onComprarClick} />}
        />
        <div className={styles.iva_incl}>
          {t(
            isAndorra
              ? 'tu_solicitud.igi_incluido'
              : 'tu_solicitud.iva_incluido'
          )}
        </div>
      </div>
      <Size.Matcher
        mobile={
          <CestaLastRow
            priceWithoutServices={totalProductos - globalDiscountAmount}
            comprarMobile={true}
            openSelectorModal={openSelectorModal}
            onContinuarClick={onComprarClick}
          />
        }
        desktop={null}
      />
      <FunnelSideBar />
    </div>
  )
}

export default TuSolicitud
